var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    {
      attrs: {
        "grid-width": "1/2",
        pageTitle: "Manage Translations for " + _vm.translationName,
      },
    },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("p", { staticClass: "text-sm" }, [
                _c("strong", [_vm._v("Description")]),
              ]),
              _c("p", { staticClass: "mt-2" }, [
                _vm._v(_vm._s(_vm.translationDescription)),
              ]),
            ]),
            _c("div", { staticClass: "vx-col w-full mt-6" }, [
              _c("p", { staticClass: "text-sm" }, [
                _c("strong", [_vm._v("Default Value")]),
              ]),
              _c("p", { staticClass: "mt-2" }, [
                _vm._v(_vm._s(_vm.translationDefaultValue)),
              ]),
            ]),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            style: { height: "300px" },
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.translationList,
              gridOptions: _vm.gridOptions,
              rowSelection: _vm.rowSelection,
              pagination: false,
              suppressPaginationPanel: true,
            },
          }),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                { staticClass: "mt-4", on: { click: _vm.onSubmit } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }